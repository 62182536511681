body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-moz-selection {
  background: #d3bd40;
}
::-webkit-selection {
  background: #d3bd40;
}
::selection {
  background: #d3bd40;
}

.scene {
  /* border: 1px solid #ccc; */
  margin: 40px 0;
  position: relative;
  width: 210px;
  height: 140px;
  margin: 40px auto;
  perspective: 1000px;
  /* background: hsl(200, 100%, 50%); */
}

.carousel {
  width: 100%;
  height: 100%;
  position: absolute;
  transform: translateZ(-288px);
  transform-style: preserve-3d;
  transition: transform 1s;
  animation: carousel-spin infinite 5s linear;
}

@keyframes carousel-spin {
  0% {
    transform: translateZ(-288px) rotateY(0deg);
  }
  15% {
    transform: translateZ(-288px) rotateY(40deg);
  }
  25% {
    transform: translateZ(-288px) rotateY(80deg);
  }
  35% {
    transform: translateZ(-288px) rotateY(120deg);
  }
  45% {
    transform: translateZ(-288px) rotateY(160deg);
  }
  55% {
    transform: translateZ(-288px) rotateY(200deg);
  }
  65% {
    transform: translateZ(-288px) rotateY(240deg);
  }
  75% {
    transform: translateZ(-288px) rotateY(280deg);
  }
  85% {
    transform: translateZ(-288px) rotateY(320deg);
  }
  /* 90% {
    transform: translateZ(-288px) rotateY(360deg);
  } */
  100% {
    transform: translateZ(-288px) rotateY(360deg);
  }
}

.carousel__cell {
  position: absolute;
  width: 190px;
  height: 120px;
  left: 10px;
  top: 10px;
  /* border: 2px solid black; */
  line-height: 116px;
  font-size: 80px;
  font-weight: bold;
  color: white;
  text-align: center;
}

/* .carousel__cell:nth-child(9n + 1) {
  background: hsla(0, 100%, 50%, 0.8);
}
.carousel__cell:nth-child(9n + 2) {
  background: hsla(40, 100%, 50%, 0.8);
}
.carousel__cell:nth-child(9n + 3) {
  background: hsla(80, 100%, 50%, 0.8);
}
.carousel__cell:nth-child(9n + 4) {
  background: hsla(120, 100%, 50%, 0.8);
}
.carousel__cell:nth-child(9n + 5) {
  background: hsla(160, 100%, 50%, 0.8);
}
.carousel__cell:nth-child(9n + 6) {
  background: hsla(200, 100%, 50%, 0.8);
}
.carousel__cell:nth-child(9n + 7) {
  background: hsla(240, 100%, 50%, 0.8);
}
.carousel__cell:nth-child(9n + 8) {
  background: hsla(280, 100%, 50%, 0.8);
}
.carousel__cell:nth-child(9n + 0) {
  background: hsla(320, 100%, 50%, 0.8);
} */

.carousel__cell:nth-child(1) {
  transform: rotateY(0deg) translateZ(288px);
}
.carousel__cell:nth-child(2) {
  transform: rotateY(40deg) translateZ(288px);
}
.carousel__cell:nth-child(3) {
  transform: rotateY(80deg) translateZ(288px);
}
.carousel__cell:nth-child(4) {
  transform: rotateY(120deg) translateZ(288px);
}
.carousel__cell:nth-child(5) {
  transform: rotateY(160deg) translateZ(288px);
}
.carousel__cell:nth-child(6) {
  transform: rotateY(200deg) translateZ(288px);
}
.carousel__cell:nth-child(7) {
  transform: rotateY(240deg) translateZ(288px);
}
.carousel__cell:nth-child(8) {
  transform: rotateY(280deg) translateZ(288px);
}
.carousel__cell:nth-child(9) {
  transform: rotateY(320deg) translateZ(288px);
}
